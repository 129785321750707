/* Global CSS */

:root {
  /* Dark theme (default) */
  --bg_color: #0a192f;
  --text_color: #8892b0;
  --heading_color: #ccd6f6;
  --accent_color: #64ffda;
  --nav_bg: rgba(10, 25, 47, 0.85);
  --card_bg: rgba(17, 34, 64, 0.95);
  --border_color: rgba(100, 255, 218, 0.1);
  --blue: #1e90ff;
}

/* Light theme overrides */
[data-theme="light"] {
  --bg_color: #f5f5f5;
  --text_color: #333333;
  --heading_color: #2d3436;
  --accent_color: #0984e3;
  --nav_bg: rgba(255, 255, 255, 0.85);
  --card_bg: rgba(255, 255, 255, 0.95);
  --border_color: rgba(9, 132, 227, 0.1);
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--bg_color);
  font-family: Arial, Helvetica, sans-serif;
  color: var(--text_color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

i {
  cursor: pointer;
  transition: color 0.5s ease-in-out;
}

i:hover {
  color: var(--blue);
}

/* Navigation */
.nav-item a {
  border-bottom: 1px solid transparent;
  color: var(--text_color);
  transition: all 0.3s ease;
}

.nav-item a:hover {
  border-bottom: 1px solid var(--blue);
  color: var(--blue);
}

/* Content sections */
.content-section {
  margin-top: 80px;
  background-color: var(--card_bg);
}

.section-header {
  text-align: center;
  font-size: 36px;
  margin-top: 60px;
  letter-spacing: 5px;
  font-family: "Times New Roman", Times, serif;
  color: var(--heading_color);
}

/* Text alignment */
.text-right {
  text-align: right;
}

/* Typography */
.content h4,
.content h5 {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
}

/* Media queries */
@media (max-width: 768px) {
  .timeline-marker {
    display: none;
  }
}

/* Visitor analytics styles */
.visitor-analytics {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.stats-section {
  background: var(--card_bg);
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
}

.stats-section h3 {
  margin-top: 0;
  color: var(--heading_color);
}

/* Scroll behavior */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

/* Rest of your CSS */
