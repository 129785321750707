.skillsSection {
  padding: 100px 0;
  background: var(--bg_color);
  min-height: 100vh;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.sectionHeader h2 {
  color: var(--heading_color);
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionNumber {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 1.25rem;
}

.headerLine {
  height: 1px;
  flex-grow: 1;
  background: var(--border_color);
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 2rem;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.skillCard {
  background: var(--card_bg);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid var(--border_color);
  position: relative;
  transition: all 0.3s ease;
  text-align: center;
}

.skillCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-color: var(--accent_color);
}

.skillIcon {
  width: 60px;
  height: 60px;
  margin: 0 auto 1rem;
}

.skillIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.skillCard h3 {
  color: var(--heading_color);
  font-size: 1rem;
  margin: 0;
  font-family: "Fira Code", monospace;
}

.cardActions {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.skillCard:hover .cardActions {
  opacity: 1;
}

.actionButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.editButton {
  color: var(--accent_color);
  border: 1px solid var(--accent_color);
}

.editButton:hover {
  background: var(--accent_color);
  color: var(--bg_color);
  transform: translateY(-2px);
}

.deleteButton {
  color: #ff4444;
  border: 1px solid #ff4444;
}

.deleteButton:hover {
  background: #ff4444;
  color: var(--bg_color);
  transform: translateY(-2px);
}

.loading {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.addCard {
  border: 2px dashed var(--accent_color);
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.addCard:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-5px);
}

.addContent {
  text-align: center;
  color: var(--accent_color);
}

.addContent i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.addContent p {
  font-family: "Fira Code", monospace;
  margin: 0;
  color: var(--accent_color);
}

@media (max-width: 768px) {
  .skillsSection {
    padding: 60px 0;
  }

  .sectionHeader h2 {
    font-size: 1.5rem;
  }

  .skillsGrid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
    padding: 0 1rem;
  }

  .skillCard {
    padding: 1.5rem;
  }

  .cardActions {
    opacity: 1;
  }

  .skillIcon {
    width: 50px;
    height: 50px;
  }
}
