.analyticsFloatBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--accent_color);
  color: var(--bg_color);
  border: 1px solid #64ffda;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
}

.analyticsFloatBtn:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
}

.analyticsModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 25, 47, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  backdrop-filter: blur(8px);
}

.analyticsModal {
  background: var(--card_bg);
  border: 1px solid rgba(100, 255, 218, 0.1);
  border-radius: 12px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: modalSlideIn 0.3s ease-out;
}

.analyticsModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid var(--border_color);
  position: sticky;
  top: 0;
  background: var(--card_bg);
  backdrop-filter: blur(8px);
}

.analyticsModalHeader h2 {
  color: var(--heading_color);
  font-size: 2rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionNumber {
  color: #64ffda;
  font-family: "Fira Code", monospace;
  font-size: 1.25rem;
}

.closeBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--text_color);
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.closeBtn:hover {
  color: #64ffda;
  background: rgba(100, 255, 218, 0.1);
}

.analyticsModalContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.statsSection {
  background: var(--card_bg);
  border: 1px solid var(--border_color);
  border-radius: 8px;
  padding: 1.5rem;
}

.statsSection h3 {
  color: var(--heading_color);
  font-family: "Fira Code", monospace;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.statsSection ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.statsSection li {
  padding: 0.75rem 0;
  border-bottom: 1px dashed rgba(100, 255, 218, 0.1);
  color: #8892b0;
}

.statsSection li:last-child {
  border-bottom: none;
}

.statsSection.totalVisitors {
  background: var(--accent_color);
  grid-column: 1 / -1;
  text-align: center;
}

.statsSection.totalVisitors h3 {
  color: #64ffda;
  font-size: 2rem;
  margin: 0;
}

.ipStatItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ip {
  font-family: "Fira Code", monospace;
  color: #64ffda;
}

.visitCount {
  color: #8892b0;
}

.recentVisitsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.recentVisitItem {
  background: var(--bg_color);
  border: 1px solid var(--border_color);
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.3s ease;
}

.recentVisitItem:hover {
  transform: translateY(-2px);
  border-color: var(--accent_color);
}

.visitTimestamp {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 0.85rem;
  margin-bottom: 0.75rem;
}

.visitDetails {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
}

.visitDetails span {
  font-family: "Fira Code", monospace;
  font-size: 0.85rem;
  color: var(--text_color);
  padding: 0.25rem 0.5rem;
  background: var(--card_bg);
  border-radius: 4px;
  border: 1px solid var(--border_color);
}

.seeMoreButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.seeMoreButton:hover {
  background: var(--border_color);
}

.seeMoreButton i {
  transition: transform 0.3s ease;
}

.seeMoreButton:hover i {
  transform: translateY(3px);
}

@keyframes modalSlideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .analyticsModalHeader h2 {
    font-size: 1.5rem;
  }

  .analyticsModalContent {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .statsSection {
    padding: 1rem;
  }
}
