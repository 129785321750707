.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
}

.modal {
  background: var(--card_bg);
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  border: 1px solid var(--border_color);
  margin-top: 70px;
  animation: modalSlideIn 0.3s ease-out;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--text_color);
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: var(--accent_color);
}

.modal h2 {
  color: var(--heading_color);
  margin-bottom: 2rem;
  font-size: 1.5rem;
  text-align: center;
  font-family: "Fira Code", monospace;
}

.inputGroup {
  margin-bottom: 1.5rem;
}

.inputGroup input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--border_color);
  border-radius: 4px;
  background: var(--bg_color);
  color: var(--text_color);
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
}

.inputGroup input:focus {
  outline: none;
  border-color: var(--accent_color);
}

.error {
  color: #ff6464;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: center;
}

.loginButton {
  width: 100%;
  padding: 0.75rem;
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  border-radius: 4px;
  cursor: pointer;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.loginButton:hover {
  background: var(--border_color);
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--accent_color);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loginButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.inputGroup input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
