.contactSection {
  background: var(--bg_color);
  padding: 100px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.sectionHeader h2 {
  color: var(--heading_color);
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionNumber {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 1.25rem;
}

.headerLine {
  height: 1px;
  flex-grow: 1;
  background: var(--border_color);
}

.contactContent {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 4rem;
  max-width: 1000px;
  margin: 0 auto;
}

.contactInfo {
  padding: 2rem;
}

.contactInfo h3 {
  color: var(--accent_color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-family: "Fira Code", monospace;
}

.contactInfo p {
  color: var(--text_color);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text_color);
}

.contactItem i {
  color: var(--accent_color);
  font-size: 1.2rem;
  width: 24px;
}

.contactItem a {
  color: var(--text_color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contactItem a:hover {
  color: var(--accent_color);
}

.contactForm {
  background: var(--card_bg);
  padding: 2.5rem;
  border-radius: 8px;
  border: 1px solid var(--border_color);
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 1rem;
  background: var(--card_bg);
  border: 1px solid var(--border_color);
  border-radius: 4px;
  color: var(--text_color);
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: var(--accent_color);
  box-shadow: 0 0 0 2px var(--border_color);
}

.formGroup input::placeholder,
.formGroup textarea::placeholder {
  color: var(--text_color);
  opacity: 0.7;
}

.submitButton {
  background: transparent;
  color: var(--accent_color);
  border: 1px solid var(--accent_color);
  padding: 1rem 2rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
}

.submitButton:hover:not(:disabled) {
  background: var(--border_color);
  transform: translateY(-2px);
}

.submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.submitButton i {
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.submitButton:hover i {
  transform: translateX(5px);
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid var(--border_color);
  border-top-color: var(--accent_color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.successMessage,
.errorMessage {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  text-align: center;
  font-family: "Fira Code", monospace;
}

.successMessage {
  background: var(--border_color);
  color: var(--accent_color);
  border: 1px solid var(--accent_color);
}

.errorMessage {
  background: rgba(255, 100, 100, 0.1);
  color: #ff6464;
  border: 1px solid rgba(255, 100, 100, 0.2);
}

@media (max-width: 768px) {
  .contactSection {
    padding: 60px 0;
  }

  .sectionHeader h2 {
    font-size: 1.5rem;
  }

  .contactContent {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 1rem;
  }

  .contactInfo {
    padding: 0;
  }

  .contactInfo h3 {
    font-size: 1.5rem;
  }

  .contactInfo p {
    font-size: 1rem;
  }

  .contactForm {
    padding: 1.5rem;
  }
}
