/* Navbar CSS */
.nav {
  padding: 0 !important;
}
/* The navigation bar */
.nav {
  overflow: hidden;
  background-color: #333;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Lower than modal overlay */
  background: var(--nav_bg);
  backdrop-filter: blur(10px);
}

.navbar {
  padding: 1rem 0;
  background: var(--nav_bg);
  backdrop-filter: blur(10px);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 1.3rem;
  text-decoration: none;
  font-weight: 700;
}

.bracket {
  color: var(--heading_color);
  opacity: 0.8;
}

.navContent {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navLinks {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navLinks a {
  color: var(--text_color);
  text-decoration: none;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  text-transform: capitalize;
  transition: color 0.3s ease;
}

.navNumber {
  color: var(--accent_color);
  margin-right: 0.5rem;
  font-size: 0.8rem;
}

.navLinks a:hover {
  color: var(--accent_color);
}

.navButtons {
  display: flex;
  gap: 1rem;
}

.authButton,
.themeButton {
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.authButton:hover,
.themeButton:hover {
  background: rgba(100, 255, 218, 0.1);
}

.menuButton {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  width: 30px;
  height: 20px;
}

.menuButton span {
  display: block;
  width: 100%;
  height: 2px;
  background: var(--accent_color);
  position: absolute;
  transition: all 0.3s ease;
}

.menuButton span:first-child {
  top: 0;
}

.menuButton span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.menuButton span:last-child {
  bottom: 0;
}

.menuButton.active span:first-child {
  transform: rotate(45deg);
  top: 50%;
}

.menuButton.active span:nth-child(2) {
  opacity: 0;
}

.menuButton.active span:last-child {
  transform: rotate(-45deg);
  bottom: 50%;
}

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }

  .navContent {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background: var(--nav_bg);
    padding: 2rem;
    flex-direction: column;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .navContent.show {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .navLinks {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .navButtons {
    flex-direction: column;
    align-items: center;
  }
}

.themeButton {
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.themeButton:hover {
  background: var(--border_color);
  transform: rotate(360deg);
}

.themeButton i {
  font-size: 1.2rem;
}
