.aboutSection {
  padding: 100px 0;
  background: var(--bg_color);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.sectionHeader h2 {
  color: var(--heading_color);
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionNumber {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 1.25rem;
}

.headerLine {
  height: 1px;
  flex-grow: 1;
  background: var(--border_color);
}

.aboutContent {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.aboutText {
  color: var(--text_color);
  opacity: 0.85;
}

.aboutText p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.8;
}

.aboutText a {
  color: var(--accent_color);
  text-decoration: none;
  position: relative;
  font-weight: 500;
}

.aboutText a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: var(--accent_color);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.aboutText a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.location {
  font-family: "Fira Code", monospace;
  color: var(--accent_color);
  margin-bottom: 2rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.location span {
  font-size: 1.2rem;
}

.skillsSection {
  margin-top: 3rem;
  background: var(--card_bg);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid var(--border_color);
}

.skillsSection h3 {
  color: var(--heading_color);
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}

.skillCategory h4 {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 1rem;
  margin-bottom: 1.2rem;
  font-weight: 500;
}

.skillsList {
  display: grid;
  gap: 0.8rem;
  padding: 0;
  list-style: none;
}

.skillsList li {
  position: relative;
  padding-left: 1.5rem;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  color: var(--text_color);
  opacity: 0.9;
}

.skillsList li::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: var(--accent_color);
  font-size: 1rem;
}

.imageWrapper {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
}

.imageWrapper::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 15px;
  left: 15px;
  border: 2px solid rgba(var(--accent_color));
  z-index: 0;
  transition: all 0.3s ease;
}

.imageWrapper img {
  position: relative;
  border-radius: 8px;
  width: 100%;
  height: auto;
  filter: grayscale(50%) brightness(0.9) contrast(1.2);
  mix-blend-mode: normal;
  transition: all 0.3s ease;
}

.imageWrapper:hover::after {
  top: 10px;
  left: 10px;
}

.imageWrapper:hover img {
  filter: none;
}

.expandButton {
  display: none;
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  width: fit-content;
  z-index: 10;
}

.expandButton:hover {
  background: rgba(var(--accent_color_rgb), 0.1);
}

.collapsed .expandableContent {
  display: none;
}

.achievements {
  margin-top: 3rem;
}

.achievements h3 {
  color: var(--heading_color);
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.achievementsList {
  display: grid;
  gap: 1rem;
  padding: 0;
  list-style: none;
}

.achievementsList li {
  position: relative;
  padding-left: 1.5rem;
  color: var(--text_color);
  font-size: 0.95rem;
  line-height: 1.6;
}

.achievementsList li::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: var(--accent_color);
  font-size: 1rem;
}

.contentCollapsed {
  max-height: 100px;
  overflow: hidden;
  position: relative;
}

.contentCollapsed::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, transparent, var(--bg_color));
  pointer-events: none;
}

.seeMoreButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  margin: 2rem auto;
  transition: all 0.3s ease;
}

.seeMoreButton:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
}

.seeMoreButton i {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.seeMoreButton:hover i {
  transform: translateY(2px);
}

@media (max-width: 768px) {
  .aboutSection {
    padding: 60px 0;
  }

  .sectionHeader h2 {
    font-size: 1.5rem;
  }

  .aboutContent {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .imageWrapper {
    max-width: 250px;
    margin: 0 auto;
  }

  .skillsGrid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .expandButton {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .aboutText {
    position: relative;
    padding-bottom: 3rem;
  }

  .collapsed {
    max-height: 300px;
    overflow: hidden;
  }

  .collapsed::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to bottom, transparent, var(--bg_color));
    pointer-events: none;
  }

  .aboutImage {
    margin-top: 2rem;
  }

  .imageWrapper img {
    filter: none;
    mix-blend-mode: normal;
  }

  .imageWrapper:hover img {
    filter: none;
    mix-blend-mode: normal;
  }

  .achievements {
    margin-top: 2rem;
  }

  .achievementsList li {
    font-size: 0.9rem;
  }

  .contentCollapsed {
    max-height: 80px;
  }

  .contentCollapsed::after {
    height: 80px;
  }

  .seeMoreButton {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    margin: 1.5rem auto;
  }
}

@media (max-width: 480px) {
  .skillsList {
    grid-template-columns: 1fr;
  }
}

.imageWrapper.editable {
  cursor: pointer;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
  border-radius: 8px;
}

.imageWrapper.editable:hover .imageOverlay {
  opacity: 1;
}

.imageOverlay i {
  color: white;
  font-size: 2rem;
}

.uploadingSpinner {
  color: var(--accent_color);
  font-size: 2rem;
}

.hiddenInput {
  display: none;
}

.profileSkeleton {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.profileImageContainer {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
