.configButton {
  position: fixed;
  bottom: 90px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: gold;
  color: #333;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  z-index: 1000;
  font-size: 24px;
}

.configButton:hover {
  transform: rotate(45deg);
}

.configModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
}

.configContent {
  background: var(--card_bg);
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid gold;
  animation: modalSlideIn 0.3s ease-out;
}

.configHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border_color);
}

.configHeader h2 {
  color: gold;
  margin: 0;
  font-family: "Fira Code", monospace;
}

.closeButton {
  background: none;
  border: none;
  color: var(--text_color);
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: gold;
}

.configBody {
  font-family: "Fira Code", monospace;
}

.configBody pre {
  background: var(--bg_color);
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  color: var(--text_color);
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.configActions {
  margin-bottom: 1rem;
  padding: 1rem;
  background: var(--bg_color);
  border-radius: 4px;
  border: 1px solid var(--border_color);
  display: flex;
  gap: 1rem;
}

.backupButton,
.restoreButton {
  background: gold;
  color: #333;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  flex: 1;
}

.restoreButton {
  background: var(--accent_color);
  color: var(--bg_color);
}

.backupButton:hover:not(:disabled),
.restoreButton:hover:not(:disabled) {
  transform: translateY(-2px);
  opacity: 0.9;
}

.backupButton:disabled,
.restoreButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.status {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
}

.status.success {
  background: rgba(0, 255, 0, 0.1);
  color: #00ff00;
  border: 1px solid #00ff00;
}

.status.error {
  background: rgba(255, 0, 0, 0.1);
  color: #ff0000;
  border: 1px solid #ff0000;
}

.configButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
