.projectsSection {
  padding: 100px 0;
  background: var(--bg_color);
  min-height: 100vh;
  position: relative;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.sectionHeader h2 {
  color: var(--heading_color);
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionNumber {
  color: var(--accent_color);
  font-family: "Fira Code", monospace;
  font-size: 1.25rem;
}

.headerLine {
  height: 1px;
  flex-grow: 1;
  background: var(--border_color);
}

.projectNav {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  border-bottom: 1px solid var(--border_color);
  background: var(--nav_bg);
  padding: 0.5rem;
  border-radius: 8px;
}

.projectNavItem {
  padding: 1rem 2rem;
  color: var(--text_color);
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  background: transparent;
  border: none;
  opacity: 0.7;
}

.projectNavItem:hover {
  color: var(--accent_color);
  opacity: 1;
}

.projectNavItem.active {
  color: var(--accent_color);
  opacity: 1;
}

.projectNavItem.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--accent_color);
  box-shadow: 0 0 8px var(--accent_color);
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 3rem;
  justify-content: center;
}

.filterButton {
  background: transparent;
  border: 1px solid var(--accent_color);
  color: var(--accent_color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filterButton:hover,
.filterButton.active {
  background: rgba(100, 255, 218, 0.1);
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.projectCard {
  background: var(--card_bg);
  border-radius: 8px;
  border: 1px solid var(--border_color);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.projectCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-color: var(--accent_color);
}

.projectImage {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.projectImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.projectCard:hover .projectImage img {
  transform: scale(1.05);
}

.projectContent {
  padding: 1.5rem;
}

.projectContent h3 {
  color: var(--heading_color);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-family: "Fira Code", monospace;
}

.projectContent p {
  color: var(--text_color);
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.techStack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.techTag {
  background: var(--border_color);
  color: var(--accent_color);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.8rem;
  font-family: "Fira Code", monospace;
}

.cardActions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.projectCard:hover .cardActions {
  opacity: 1;
}

.actionButton {
  background: var(--card_bg);
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}

.editButton {
  color: var(--accent_color);
  border: 1px solid var(--accent_color);
}

.editButton:hover {
  background: var(--accent_color);
  color: var(--bg_color);
  transform: translateY(-2px);
}

.deleteButton {
  color: #ff4444;
  border: 1px solid #ff4444;
}

.deleteButton:hover {
  background: #ff4444;
  color: var(--bg_color);
  transform: translateY(-2px);
}

.addCard {
  border: 2px dashed var(--accent_color);
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.addCard:hover {
  background: rgba(100, 255, 218, 0.1);
}

.addContent {
  text-align: center;
  color: var(--accent_color);
}

.addContent i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.addContent p {
  font-family: "Fira Code", monospace;
  margin: 0;
  color: var(--accent_color);
}

.loading {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

@media (max-width: 768px) {
  .projectsSection {
    padding: 60px 0;
  }

  .sectionHeader h2 {
    font-size: 1.5rem;
  }

  .projectNav {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .projectNavItem {
    padding: 0.75rem 1rem;
    font-size: 0.8rem;
  }

  .projectsGrid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .filterContainer {
    padding: 0 1rem;
  }

  .cardActions {
    opacity: 1;
  }

  .projectCard {
    margin-bottom: 1rem;
  }
}
